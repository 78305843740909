import React, {
  useEffect,
  Fragment,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";
import ActionCable from "actioncable";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import NotificationSound from "../../layouts/Dashboard/NotificationSound";
import CallAlert from "layouts/Dashboard/CallAlert";
import { useLocation } from "react-router-dom";
import { refreshContext } from "../../RefreshPrescription";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const newCable = (token) =>
  ActionCable.createConsumer(`${process.env.REACT_APP_SOCKET_URL}${token}`);

const ActionCableNotificationChannel = ({ handleNotification, children }) => {
  const token = localStorage.getItem("jwt");
  const consumer = useRef(null);

  const [message, setMessage] = useState("");
  const [openSnack, setOpenSnack] = useState(true);
  const [severity, setSeverity] = useState("info");
  const [soundStatus, setSoundStatus] = useState(false);
  const [openCallBackdrop, setOpenCallBackdrop] = useState(false);
  const { setPrescriptionCount } = useContext(refreshContext);
  const location = useLocation();
  const handleCloseCallBackdrop = () => {
    setOpenCallBackdrop(false);
  };
  const handleToggleCallBackdrop = (value) => {
    setOpenCallBackdrop(value);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleReceived = useCallback(
    (data) => {
      try {
        if (data) {
          console.log("received message:", data);
          setMessage(data);
          if (
            data.notification_type === "alert" &&
            data.hasOwnProperty("prescription_sent_to_mso") &&
            data.prescription_sent_to_mso === 1 &&
            location.pathname === "/"
          ) {
            setPrescriptionCount((prev) => prev + 1);
          }
          if (
            data.notification_type === "alert" &&
            data.hasOwnProperty("call_ended")
          ) {
            localStorage.setItem("callEndedStatus", data.call_ended);
            localStorage.setItem("isCallExist", "0");
            handleCloseCallBackdrop();
          }
          if (
            data.notification_type === "alert" &&
            data.hasOwnProperty("doctor_window_closed") &&
            data.doctor_window_closed === 1
          ) {
            localStorage.setItem("callEndedStatus", "1");
            handleSoundStatus(true);
            localStorage.setItem("isCallExist", "0");
            handleCloseCallBackdrop();
          }
          if (data.notification_type === "incoming_call") {
            localStorage.setItem("vonageData", JSON.stringify(data.data));
            localStorage.setItem("isCallExist", "1");
            handleToggleCallBackdrop(true);
            localStorage.setItem(
              "vonageSession",
              JSON.stringify(data.data.vonage_session_id)
            );
            localStorage.setItem("callEndedStatus", 0);
          }
          if (data.notification_type === "call_answered") {
            // this will stop the sound from playing, also the child
            // component will also attempt to close the backdrop
            setSoundStatus(true);
            setOpenCallBackdrop(false);
          }
          setOpenSnack(true);
        } else {
          setMessage("res not OK");
          setOpenSnack(true);
          setSeverity("warning");
        }
      } catch (err) {
        setMessage("Error from catch");
        setOpenSnack(true);
        setSeverity("error");
      }
    },
    [location.pathname, setPrescriptionCount]
  );

  const connectCable = useCallback(() => {
    consumer.current.subscriptions.create("NotificationChannel", {
      initialized: () => console.log("Connecting....."),
      connected: () => console.log("Way to go! You did it!"),
      received: (data) => {
        handleNotification(data);
        // care should be taken to ensure when the changes are applied on the
        // backend regarding the type of the notification, this handling function
        // will have to be made more robust. Again using TSX would help, since things
        // are being passed around so much
        handleReceived(data);
      },
      disconnected: () => console.log("We are no longer connected! 😢"),
    });
  }, [handleReceived, handleNotification]);

  useEffect(() => {
    if (token) {
      consumer.current = newCable(token);
      connectCable();
    }
    return () => {
      if (consumer.current) {
        consumer.current.disconnect();
      }
    };
  }, [connectCable, token]);

  const handleSoundStatus = (value) => {
    setSoundStatus(value);
  };

  const handleVideoCallResponse = () => {
    window.open(
      "/vonage",
      "_blank",
      "width = 600, height = 600,top=0, left=760"
    );
    const subs = consumer.current.subscriptions.create({
      channel: "NotificationChannel",
    });
    subs.perform("call_answered");
    subs.unsubscribe();
    handleSoundStatus(true);
    handleCloseCallBackdrop();
  };

  return (
    <Fragment>
      <CallAlert
        handleVideoCallResponse={handleVideoCallResponse}
        open={openCallBackdrop}
      />
      {/* the handling of the incoming message can be improved */}
      {message ? (
        <>
          <NotificationSound
            notificationType={message.notification_type}
            handleSoundStatus={(value) => handleSoundStatus(value)}
            soundStatus={soundStatus}
            handleCloseCallBackdrop={handleCloseCallBackdrop}
            message={message}
          />
          {message.notification_type !== "incoming_call" &&
            localStorage.getItem("isCallExist") !== "1" && (
              <Snackbar
                open={openSnack}
                style={{ top: "10%" }}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                autoHideDuration={50000}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity={severity}>
                  {message.data}
                </Alert>
              </Snackbar>
            )}
        </>
      ) : null}
      {children}
    </Fragment>
  );
};

export default ActionCableNotificationChannel;
